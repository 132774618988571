
import { defineComponent, onMounted ,ref , watch, } from "vue";
import { useRouter,  useRoute } from "vue-router";
import { useI18n } from "vue-i18n";
import { MenuComponent } from "@/assets/ts/components";
import MainMenuConfig from "@/core/config/MainMenuConfig";
import { headerMenuIcons } from "@/core/helpers/config";
import AuthService from "@/services/AuthService";
import store from "@/store/index";
import { Actions, Mutations } from "@/store/enums/StoreEnums";

export default defineComponent({
  name: "KTMenu",
  components: {},
  setup() {
    const { t, te } = useI18n();
    const route = useRoute();
    const router = useRouter();
    
    let dominio_gestao = store.getters.layoutConfig("distribuidor.dominio_gestao");
    let tipo_conexao = store.getters.layoutConfig("distribuidor.tipo_conexao");
    let distribuidor = store.getters.layoutConfig("distribuidor.distribuidor");

    const hidenMenuHeaders = ['stellantis']
    const userInfo = AuthService.getUsuarioAuth();
    const codNivel = userInfo.codNivel;

    const menuHeaders = [
      {
        sectionTitle: "Dashboards",
        route: "/subscriptions1",
        codRequired: [-1, 1, 2],
        hiddenDistribuidor : ['stellantis'],
        sub: [
          {
            heading: "One Page Report",
            route: "dashboard",
            svgIcon: "./media/icons/duotune/files/fil024.svg"
          },
          {
            heading: "Por Grupo",
            route: "/one-page-report-grupo",
            svgIcon: "./media/icons/duotune/graphs/gra010.svg"
          },
          {
            heading: "Por marca",
            route: "/one-page-report-marca",
            svgIcon: "./media/icons/duotune/technology/teh004.svg"
          },
          {
            heading: "Business Inteligence",
            route: "/dashboard.php",
            svgIcon: "./media/icons/duotune/technology/teh003.svg"
          },
          {
            heading: "Novo Business Inteligence",
            route: "/business-inteligence",
            svgIcon: "./media/icons/duotune/technology/teh003.svg"
          },
        ],
      },
    ]

    const hasActiveChildren = (match) => {
      return route.path.indexOf(match) !== -1;
    };

    const translate = (text) => {
      if (te(text)) {
        return t(text);
      } else {
        return text;
      }
    };
     const notificationRef = ref(store.getters.notificationsSession);

    onMounted(() => {
      MenuComponent.reinitialization();
    });



      store.watch(() => store.getters.notificationsSession, async () => {
              notificationRef.value = store.getters.notificationsSession;			
			}, {deep:true});

      function goTo(){
         router.push("/oficina-digital/gerenciar");
      }

      function limpar(){
     store.commit(Mutations.DELETE_ALL_NOTIFICATION);
     store.dispatch(Actions.ALTER_TITLE);
      }
    return {
      hasActiveChildren,
      headerMenuIcons,
      MainMenuConfig,
      menuHeaders,
      translate,
      codNivel,
      notificationRef,
      goTo,
      limpar,
      distribuidor,
      hidenMenuHeaders
    };
  },
});
