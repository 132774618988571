
import { defineComponent, onMounted } from "vue";
import { useRoute } from "vue-router";
import { useI18n } from "vue-i18n";
import { MenuComponent } from "@/assets/ts/components";
import MainMenuConfig from "@/core/config/MainMenuConfig";
import { headerMenuIcons } from "@/core/helpers/config";
import UserMenu from "@/layout/header/partials/UserMenu.vue";
import AuthService from "@/services/AuthService";
import store from "@/store/index";
import {version} from '@/../package.json'
import distribuidorConfig from "@/core/config/DistribuidorConfig";


export default defineComponent({
  name: "KTMenu",
  components: { UserMenu },
  setup() {
    const { t, te } = useI18n();
    const route = useRoute();
    const appVersion = version;
   
    let dominio_gestao = store.getters.layoutConfig("distribuidor.dominio_gestao");
    let tipo_conexao = store.getters.layoutConfig("distribuidor.tipo_conexao");
    let distribuidor = store.getters.layoutConfig("distribuidor.distribuidor");

    const userInfo = AuthService.getUsuarioAuth();
    const codNivel = userInfo.codNivel;
    const userId = userInfo.codUsuario;


    const menuHeaders = [
      {
        sectionTitle: "Dashboards",
        route: "/subscriptions1",
        codRequired: [-1, 1, 2],
        hiddenDistribuidor : ['stellantis'],
        sub: [
          {
            heading: "One Page Report",
            route: "dashboard",
            svgIcon: "./media/icons/duotune/files/fil024.svg",
            hiddenDistribuidor : ['stellantis']
          },
          {
            heading: "Por Grupo",
            route: "/one-page-report-grupo",
            svgIcon: "./media/icons/duotune/graphs/gra010.svg"
          },
          {
            heading: "Por marca",
            route: "/one-page-report-marca",
            svgIcon: "./media/icons/duotune/technology/teh004.svg"
          },
          {
            heading: "Business Inteligence",
            route: "/dashboard.php",
            svgIcon: "./media/icons/duotune/technology/teh003.svg"
          },
          {
            heading: "Novo Business Inteligence",
            route: "/business-inteligence",
            svgIcon: "./media/icons/duotune/technology/teh003.svg"
          },
        ],
      },
    ]

    const menuHeadersWithoutIcon = [
      {
        sectionTitle: "Ações Rápidas",
        route: "/ações",
        codRequired: [-1, 2],
        hiddenDistribuidor : ['stellantis'],
        sub: [
          {
            heading: "Lançamento individual",
            route: "/vendas/lancamento-mensal",
          },
          {
            heading: "Lançamento por Grupo",
            route:  "/lancamentoaddgrupomarca.php",
          },
          {
            heading: "Lançamento Lote Workflow",
            route: "/vendas/lancamento-mensal/workflow",
          },
          {
            heading: "Solicitar suporte",
            route: "https://camptecnologia.atlassian.net/servicedesk/customer/portal/3",
          },
        ],
      },
    ];

    function refresh(){
      location.reload(true);
    }

    const hasActiveChildren = (match) => {
      return route.path.indexOf(match) !== -1;
    };

    const translate = (text) => {
      if (te(text)) {
        return t(text);
      } else {
        return text;
      }
    };

    onMounted(() => {
      MenuComponent.reinitialization();
    });

    return {
      hasActiveChildren,
      headerMenuIcons,
      MainMenuConfig,
      menuHeaders,
      menuHeadersWithoutIcon,
      translate,
      codNivel,
      userId,
      distribuidor,
      appVersion,
      refresh
    };
  },
});
